.header {
  position: relative;
  pointer-events: none;
  @include breakpoint($mobile){
    position: absolute;
    z-index: 100;
    width: 100%;
  }
  @include breakpoint($tablet){
    position: absolute;
    width: 100%;
    z-index: 102;
  }
  @include breakpoint($laptop){
    position: fixed;
  }
  .container {
    position: relative;

    &:before {
      pointer-events: none;
      content: " ";
      position: absolute;
      top: 0; left: 0; right: 0;
      width: 100%;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f5f5f5+0,f5f5f5+100 */
      background: rgb(245,245,245); /* Old browsers */
      background: -moz-linear-gradient(top, rgba(245,245,245,1) 0%, rgba(245,245,245,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(245,245,245,1) 0%,rgba(245,245,245,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(245,245,245,1) 0%,rgba(245,245,245,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#f5f5f5',GradientType=0 ); /* IE6-9 */
      transition: background .3s ease 0s;
      @include breakpoint($mobile){
        display: none;
      }
      @include breakpoint($tablet){
        display: none;
      }
      @include breakpoint($laptop){
        display: block;
        height: 150px;
      }
      @include breakpoint($desktop){
        height: 180px;
      }
    }
  }
  .menu-opener {
    pointer-events: auto;
    @include breakpoint($mobile){
      display: none;
      pointer-events: none;
      position: absolute;
      top: 50%; right: 5px;
      margin-top: -25px;
      width: 50px;
      height: 50px;
      background: transparent;
      border: none;
      z-index: 10000;
      &:hover {
        cursor: pointer;
      }
      .bar {
        position: absolute;
        width: 25px;
        height: 2px;
        background: $white;
        display: inline-block;
        top: 50%; margin-top: -1px;
        left: 50%; margin-left: -12.5px;
        &:after, &:before {
          content: " ";
          position: absolute;
          top: -8px; left: 0;
          width: 25px;
          height: 2px;
          background: $white;
          transition: transform .2s ease;
        }
        &:before {
          top: auto;
          bottom: -8px;
        }
      }
    }
    @include breakpoint($tablet){
      display: none;
      pointer-events: none;
      position: absolute;
      top: 50%; right: 5px;
      margin-top: -25px;
      width: 50px;
      height: 50px;
      background: transparent;
      border: none;
      z-index: 10000;
      &:hover {
        cursor: pointer;
      }
      .bar {
        position: absolute;
        width: 25px;
        height: 2px;
        background: $white;
        display: inline-block;
        top: 50%; margin-top: -1px;
        left: 50%; margin-left: -12.5px;
        &:after, &:before {
          content: " ";
          position: absolute;
          top: -8px; left: 0;
          width: 25px;
          height: 2px;
          background: $white;
          transition: transform .2s ease;
        }
        &:before {
          top: auto;
          bottom: -8px;
        }
      }
    }
    @include breakpoint($laptop){
      display: none;
    }
  }
  &-logo {
    background: $blue;
    display: block;
    pointer-events: auto;
    @include breakpoint($mobile){
      padding: 15px 0;
      display: flex;
      position: relative;
      z-index: 100;
      width: 130px;
      height: 130px;
      transform: translateX(25px);
      transition: transform .2s ease;
    }
    @include breakpoint($tablet){
      padding: 15px 0;
      position: relative;
      z-index: 100;
      width: 180px;
      height: 180px;
      display: flex;
      transform: translateX(90px);
      transition: transform .2s ease, width .2s ease;
    }
    @include breakpoint($laptop){
      padding: 75px 0;
      width: 100%;
      height: auto !important;
      height: 10px;
      display: block;
      transform: translateX(0);
      transition: padding .2s ease;
    }
    @include breakpoint($desktop){
      padding: 90px 0;
    }
    img {
      display: block;
      transition: max-width .2s ease;
      max-width: 90px;
      @include breakpoint($mobile){
        max-width: 60px;
        margin: 0 auto;
      }
      @include breakpoint($tablet){
        max-width: 90px;
        margin: 0 auto;
      }
      @include breakpoint($laptop){
        max-width: 120px;
        margin: 0 auto;
      }
    }
  }
  &-nav {
    width: 100%;
    display: flex;
    pointer-events: auto;
    @include breakpoint($mobile){
      position: absolute;
      display: none;
      pointer-events: none;
      background: $blue;
      z-index: 99;
      top: 0; right: 0;
      justify-content: flex-start;
      align-items: flex-start;
      transform: translateY(-150%);
      transition: transform .3s ease;
    }
    @include breakpoint($tablet){
      position: absolute;
      display: none;
      pointer-events: none;
      background: $blue;
      z-index: 99;
      top: 0; right: 0;
      justify-content: flex-start;
      align-items: flex-start;
      transform: translateY(-150%);
      transition: transform .3s ease;
    }
    @include breakpoint($laptop){
      display: flex;
      pointer-events: auto;
      height: 160px;
      transform: translateY(0);
      justify-content: flex-end;
      align-items: center;
      background: transparent;
      position: relative;
    }
    @include breakpoint($desktop){
      height: 190px;
    }
    ul {
      display: flex;
      @include breakpoint($mobile){
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 25px;
      }
      @include breakpoint($tablet){
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 25px;
      }
      @include breakpoint($laptop){
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        padding: 0;
      }
      li {
        @include breakpoint($mobile){
          margin: 0;
          height: 40px;
          display: flex;
          align-items: center;
        }
        @include breakpoint($tablet){
          margin: 0;
          height: 40px;
          display: flex;
          align-items: center;
        }
        @include breakpoint($laptop){
          height: auto;
          display: inline-block;
          margin-right: 25px;
          &:last-child {
            margin-right: 0;
          }
        }
        a {
          font-family: $font-sans;
          text-transform: uppercase;
          font-size: .8rem;
          display: inline-block;
          text-decoration: none !important;
          position: relative;
          @include breakpoint($mobile){
            color: $white !important;
          }
          @include breakpoint($tablet){
            color: $white;
          }
          @include breakpoint($laptop){
            color: $black;
            top: -5px;
          }
          &:after {
            content: " ";
            position: absolute;
            bottom: -8px;
            left: 50%;
            margin-left: -3px;
            width: 6px;
            height: 6px;
            background: $blue;
            border-radius: 6px;
            transform: translateY(15px);
            opacity: 0;
            transition: transform .2s ease, opacity .2s ease;
            @include breakpoint($mobile){
              display: none;
            }
          }
          &:hover {
            &:after {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
        .instagram {
          font-size: 2rem;
          position: relative;
          top: -4px;
          @include breakpoint($mobile){
            color: $white !important;
            font-size: 1.5rem;
          }
          @include breakpoint($tablet){
            color: $white !important;
            font-size: 1.5rem;
          }
          @include breakpoint($laptop){
            color: $blue !important;
          }
          &:after {
            opacity: 0 !important;
          }
        }
        .facebook {
          font-size: 1.8rem;
          position: relative;
          top: -4px;
          @include breakpoint($mobile){
            color: $white !important;
            font-size: 1.5rem;
          }
          @include breakpoint($tablet){
            color: $white !important;
            font-size: 1.5rem;
          }
          @include breakpoint($laptop){
            color: $blue !important;
          }
          &:after {
            opacity: 0 !important;
          }
        }
      }
    }
  }
  &.scrolled {
    .header-logo {
      padding: 40px 0;
      @include breakpoint($mobile){
        padding: 15px 0;
      }
      @include breakpoint($tablet){
        padding: 15px 0;
      }
      @include breakpoint($laptop){
        padding: 20px 0;
      }
      img {
        @include breakpoint($tablet){
          max-width: 80px;
        }
        @include breakpoint($laptop){
          max-width: 75px;
        }
      }
    }
    .container {
      &:before {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
        background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
        transition-delay: .1s;
      }
    }
    .header-nav {
      @include breakpoint($laptop) {
        height: 140px;
      }
    }
  }
}

.menu-opened {

  .header {
    &-nav {
      @include breakpoint($mobile){
        transform: translateY(0);
      }
      @include breakpoint($tablet){
        transform: translateY(0);
      }
    }
    .menu-opener {
      .bar {
        &:after {
          transform: translateY(8px);
        }
        &:before {
          transform: translateY(-8px);
        }
      }
    }
  }

}