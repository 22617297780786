section {
  padding: 120px 0;
  @include breakpoint($mobile){
    padding: 90px 0;
  }
}

.mission {
  padding: 0;
  @include breakpoint($mobile){
    margin-top: 100px;
  }
  @include breakpoint($tablet){
    margin-top: 150px;
  }
  @include breakpoint($laptop){
    margin-top: 160px;
    position: relative;
    z-index: 101;
  }
  @include breakpoint($desktop){
    margin-top: 190px;
  }
  .container {
    position: relative;
    &:after {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 50px; left: 0; right: 0;
      background: $grey;
      z-index: -1;
      @include breakpoint($mobile){
        display: none;
      }
    }
  }
  .block {
    position: relative;
    .picture {
      position: relative;
      @include breakpoint($mobile){
        height: 300px;
        width: calc(100% + 30px);
        transform: translate(-15px);
      }
      @include breakpoint($tablet){
        width: 100%;
        left: 0;
        height: 300px;
      }
      @include breakpoint($laptop){
        left: 27.15%;
        width: 72.75%;
        height: 400px;
      }
      @include breakpoint($desktop){
        left: 27.7%;
        width: 72.3%;
      }
      &:after {
        content: " ";
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        width: 100%;
        height: 100%;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e9bbac+0,e9bbac+100&1+0,0+100 */
        background: -moz-linear-gradient(left, rgba(233,187,172,1) 0%, rgba(233,187,172,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(233,187,172,1) 0%,rgba(233,187,172,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(233,187,172,1) 0%,rgba(233,187,172,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9bbac', endColorstr='#00e9bbac',GradientType=1 ); /* IE6-9 */
      }
      img {
        object-fit: cover;
        width: 100%;
        @include breakpoint($mobile){
          height: 300px;
        }
        @include breakpoint($tablet){
          height: 300px;
        }
        @include breakpoint($laptop){
          height: 400px;
        }
      }
    }
    .content {
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      z-index: 10;
      @include breakpoint($mobile){
        height: 300px;
        padding: 25px;
      }
      @include breakpoint($tablet){
        height: 300px;
        padding: 30px;
      }
      @include breakpoint($laptop){
        height: 400px;
        padding: 0;
      }
    }
  }
  &-title {
    font-family: $font-serif;
    color: $blue;
    @include breakpoint($mobile){
      font-size: 2rem;
      line-height: 2.5rem;
    }
    @include breakpoint($tablet){
      font-size: 2rem;
      line-height: 2.5rem;
      max-width: 300px;
    }
    @include breakpoint($laptop){
      max-width: 500px;
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
  .btn {
    margin-top: 30px;
    @include breakpoint($mobile){
      border-color: $white;
      margin-top: 20px;
      &:before {
        background: $white;
        transform: translateY(0);
      }
      &:hover {
        color: $blue;
      }
    }
    @include breakpoint($tablet){
      border-color: $white;
      &:before {
        background: $white;
      }
      &:hover {
        color: $blue;
      }
    }
    @include breakpoint($laptop){
      border-color: $gold;
      &:before {
        background: $gold;
      }
      &:hover {
        color: $white;
      }
    }
  }
}

.intro {
  h1 {
    color: $gold;
    margin-bottom: 70px;
  }
}

.little-things {
  padding-top: 0;
  .picture {
    width: 100%;
    position: relative;
    background-image: url('../img/01.png');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint($mobile){
      height: 300px;
    }
    @include breakpoint($tablet){
      height: 300px;
    }
    @include breakpoint($laptop){
      height: 400px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    h1 {
      z-index:  10;
      display: block;
      max-width: 300px;
      margin: 0 auto;
      z-index: 10;
      text-align: center;
      color: $blue;
      @include breakpoint($mobile){
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding: 0 15px;
      }
      @include breakpoint($tablet){
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding: 0 15px;
      }
      @include breakpoint($laptop){
        font-size: 2.2rem;
        line-height: 2.8rem;
        padding: 0;
      }
    }
  }
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    @include breakpoint($mobile){
      height: 300px;
    }
    @include breakpoint($tablet){
      height: 300px;
    }
    @include breakpoint($laptop){
      height: 400px;
    }
    img {
      display: block;
      @include breakpoint($mobile){
        max-width: 150px;
      }
      @include breakpoint($tablet){
        max-width: 150px;
      }
      @include breakpoint($laptop){
        max-width: 200px;
      }
    }
  }
}

.work {
  padding: 0;
  margin-top: -180px;
  margin-bottom: 120px;
  position: relative;
  z-index: -1;
  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 200px;
    background: $white;
    bottom: 0; right: 0; left: 0;
  }
  &-row {
    padding-top: 200px;
  }
  &-second-row {
    margin-top: 30px;
  }
  .container {
    background: $grey;
  }
  .h2 {
    color: $blue;
  }
  &-container {
    width: 100%;
    position: relative;
    .title {
      position: absolute;
      top: 20px; left: 0;
      z-index: 10;
      color: $blue;
    }
    .picture {
      width: 85%;
      position: absolute;
      height: 200px;
      right: 0;
      img {
       object-fit: cover;
        width: 100%; height: 100%;
      }
    }
    &.big {
      height: 300px;
      .title {
        left: 20px;
      }
      .picture {
        width: 75%;
        height: 300px;
      }
    }
    &.small {
      height: 170px;
      .title {
        right: 0;
        left: auto;
      }
      .picture {
        width: 80%;
        right: auto;
        left: 0;
        height: 170px;
      }
    }
    &.medium {
      height: 200px;
      .picture {
        height: 200px;
      }
    }
  }
}

.coffret {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 10;
  .h2 {
    color: $blue;
  }
  &-row {
    margin-top: 30px;
  }
  .film-container {
    background: $gold;
    padding: 75px;
    margin-top: 100px;
    position: relative;
    z-index: 0;
    @include breakpoint($mobile){
      padding: 50px 30px;
      padding-bottom: 130px;
    }
    &:before {
      content: " ";
      position: absolute;
      width: calc(100% + 50px);
      z-index: -1;
      height: 100%;
      background: $gold;
      padding: 100px;
      top: 0; left: 0;
      @include breakpoint($mobile){
        display: none;
      }
      @include breakpoint($tablet){
        display: none;
      }
      @include breakpoint($laptop){
        display: block;
      }
    }
    h2 {
      font-family: $font-serif;
      color: $blue;
      font-size: 3rem;
    }
    h3 {
      font-family: $font-serif;
      color: $blue;
      font-size: 1.1rem;
      margin-top: 10px;
    }
    ul {
      margin-top: 20px;
    }
  }
  .goodies {
    background: $blue;
    padding: 50px;
    position: relative;
    @include breakpoint($mobile){
      padding: 50px 30px;
      padding-top: 80px;
    }
    @include breakpoint($tablet){
      padding-bottom: 50px;
    }
    @include breakpoint($laptop){
      padding-bottom: 150px;
    }
    &:before {
      content: " ";
      position: absolute;
      width: 100%;
      z-index: -1;
      height: 100%;
      background: $blue;
      padding: 100px;
      top: 0; left: 0;
      @include breakpoint($mobile){
        display: none;
      }
      @include breakpoint($tablet){
        display: none;
      }
      @include breakpoint($laptop){
        display: block;
      }
    }
    h2 {
      font-family: $font-serif;
      color: $gold;
      font-size: 3rem;
    }
    ul {
      margin-top: 20px;
      li {
        color: $white;
      }
    }
    .cadre {
      position: absolute;
      display: block;
      z-index: 10;
      @include breakpoint($mobile){
        width: 100px;
        bottom: -40px;
        left: 30px;
      }
      @include breakpoint($tablet){
        width: 130px;
        top: -30px;
        left: -30px;
      }
      @include breakpoint($laptop){
        width: 130px;
        top: 50px;
        left: -90px;
      }
    }
    .ipad {
      position: absolute;
      display: block;
      width: 450px;
      z-index: 10;
      @include breakpoint($mobile){
        left: auto;
        right: -5px;
        bottom: auto;
        top: -115px;
        width: 250px;
      }
      @include breakpoint($tablet){
        left: auto;
        right: -50px;
        bottom: auto;
        top: -100px;
      }
      @include breakpoint($laptop){
        left: -80px;
        bottom: -200px;
        top: auto;
      }
    }
  }
}

.contact {
  padding-top: 0;
  .container {
    background: $grey;
    padding-bottom: 120px;
    position: relative;
    &:before {
      content: " ";
      position: absolute;
      top: -110px; left: 0; right: 0;
      width: 100%;
      height: 110px;
      background: $grey;
      z-index: -1;
    }
  }
  h1 {
    padding-top: 120px;
    text-align: center;
    display: block;
    color: $blue;
    &:after {
      left: 50%;
      margin-left: -25px;
    }
  }
  &-row {
    margin-top: 120px;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      li {
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          font-family: $font-serif;
          font-size: 2rem;
          color: $blue;
          display: inline-block;
          text-decoration: none !important;
          transition: color .2s ease, transform .2s ease;
          @include breakpoint($mobile){
            font-size: 1.1rem;
          }
          &:hover {
            color: $gold;
            transform: scale(1.05);
          }
        }
        .instagram {
          font-size: 4rem;
          color: $gold !important;
          margin-top: 30px;
          display: inline-block;
          @include breakpoint($mobile){
            font-size: 3rem;
            margin-top: 20px;
          }
        }
        .facebook {
          font-size: 4rem;
          color: $gold !important;
          margin-top: 30px;
          display: inline-block;
          margin-left: 30px;
          @include breakpoint($mobile){
            font-size: 3rem;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

// PROJECT

.project {
  @include breakpoint($tablet){
    padding-top: 40px;
  }
  @include breakpoint($desktop){
    padding-top: 120px;
  }
  .container {
    position: relative;
    &:before {
      content: " ";
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      width: 100%;
      background: $grey;
      z-index: -1;
      @include breakpoint($mobile){
        transform: translateY(-100px);
        height: 100%;
      }
      @include breakpoint($tablet){
        transform: translateY(-30%);
        height: calc(100% + 5%);
      }
      @include breakpoint($laptop){
        transform: translateY(-40%);
        height: calc(100% + 20%);
      }
    }
  }
  &-preview {
    width: 100%;
    height: 300px;
    position: relative;
    margin-bottom: 30px;
    &:after {
      content: " ";
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ead0c7+0,7db9e8+100&1+0,0+100 */
      background: -moz-linear-gradient(left, rgba(234,208,199,1) 0%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(234,208,199,1) 0%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(234,208,199,1) 0%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ead0c7', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
      transform: scale(1);
      transition: transform .3s ease;
    }
    img {
      position: absolute;
       top: 0; left: 0; right: 0; bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transform: scale(1) scaleX(-1);
      transition: transform .3s ease;
    }
    h2 {
      position: absolute;
      z-index: 10;
      font-size: 3rem;
      line-height: 3.5rem;
      font-family: $font-serif;
      color: $blue;
      top: 50px;
      transform: translateX(0);
      transition: transform .3s ease;
      @include breakpoint($mobile){
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
    &.title-left {
      h2 {
        left: -45px;
        @include breakpoint($mobile){
          left: 30px;
        }
      }
    }
    &.title-right {
      &:after {
        transform: scaleX(-1);
      }
      h2 {
        right: -45px;
        @include breakpoint($mobile){
          left: auto;
          right: 30px;
        }
      }
    }
    &:hover {
      &:after {
        transform: scale(1.05);
        @include breakpoint($mobile){
          transform: scale(1);
        }
      }
      img {
        transform: scale(1.05) scaleX(-1);
        @include breakpoint($mobile){
          transform: scale(1) scaleX(-1);
        }
      }
      &.title-left {
        h2 {
        transform: translateX(-20px);
          @include breakpoint($mobile){
            transform: translateX(0);
          }
          }
      }
      &.title-right {
        &:after {
          transform: scale(1.05) scaleX(-1);
          @include breakpoint($mobile){
            transform: scale(1) scaleX(-1);
          }
        }
        h2 {
        transform: translateX(20px);
          @include breakpoint($mobile){
            transform: translateX(0);
          }
          }
      }
    }
  }
  h1 {
    color: $blue;
    margin-bottom: 50px;
  }
  .video-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    span:first-child {
      font-size: .8rem;
      font-family: $font-sans;
      text-transform: uppercase;
      color: $blue;
    }
    span:last-child {
      position: relative;
      top: -10px;
      color: $blue;
      font-size: 2.3rem;
    }
  }
  .video {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0; right: 0; left: 0; bottom: 0;
    }
  }
}

.video-box {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background: $blue;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-content: center;
  opacity: 0;
  pointer-events: none !important;
  transition: opacity .5s ease, transform 0s linear;
  background-image: url(../img/logo.svg);
  background-size: 70px;
  background-repeat: no-repeat;
  background-position: center center;
  transform: translateX(-100%);
  .video-container {
    width: 70%;
    height: auto;
    position: relative;
    @include breakpoint($mobile){
      width: 95%;
    }
    iframe {
      position: absolute;
      top: 0; left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity .5s ease;
      transition-delay: 0s;
    }
  }
  .close {
    position: absolute;
    width: 50px;
    height: 50px;
    background: transparent;
    top: 30px;
    left: 50%;
    margin-left: -25Px;
    border: none;
    outline: none !important;
    z-index: 1000;
    &:after, &:before {
      content: " ";
      position: absolute;
      width: 35px;
      height: 2px;
      background: $white;
      top: 50%;
      margin-top: -1px;
      left: 50%;
      margin-left: -17.5px;
      opacity: .65;
      transition: opacity .2s ease;
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:before {
      transform: rotate(45deg);
    }
    &:hover {
      cursor: pointer;
      &:after, &:before {
        opacity: 1;
      }
    }
  }
  &.active {
    pointer-events: auto !important;
    opacity: 1;
    transform: translateX(0);
    .video-container {
      iframe {
        opacity: 1;
        transition-delay: .2s;
      }
    }
  }
}