.btn {
  font-size: .7rem;
  font-family: $font-sans;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 15px 25px;
  border: 2px solid $gold;
  color: $blue;
  border-radius: 50px;
  z-index: 1;
  overflow: hidden;
  position: relative;
  transition: color .1s ease;
  &:before {
    content: " ";
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    height: 100%;
    background: $gold;
    z-index: -1;
    transform: translateY(-100%);
    transition: transform .2s ease;
  }
  &:hover {
    color: $white;
    &:before {
      transform: translateY(0);
    }
  }
}