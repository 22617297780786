// Open Sans

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

// Gotham & Magnel

@font-face {
  font-family: 'Magnel Display';
  src: url('../fonts/magnel/MagnelDisplay-Black.eot');
  src: url('../fonts/magnel/MagnelDisplay-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/magnel/MagnelDisplay-Black.woff') format('woff'),
  url('../fonts/magnel/MagnelDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/gotham/GothamRounded-Bold.eot');
  src: url('../fonts/gotham/GothamRounded-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham/GothamRounded-Bold.woff') format('woff'),
  url('../fonts/gotham/GothamRounded-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

$blue : #34547d;
$black: #3b3b3b;
$gold: #ead0c7;
$grey: #f5f5f5;
$white: #FFFFFF;

$body-font: 'Open sans', sans-serif;
$font-serif: 'Magnel Display';
$font-sans: 'Gotham Rounded';

$iphone:       ( max:  320px );
$mobile:       ( max:  767px );
$tablet:       ( min:  768px );
$laptop:       ( min:  992px );
$desktop:      ( min: 1200px );
$tv:           ( min: 1640px );

@mixin breakpoint($map) {
  $query: "";
  @if map-has-key($map, min) { $query: append($query, "(min-width: #{map-get($map, min)})") }
  @if map-has-key($map, min) and map-has-key($map, max) { $query: append($query, "and") }
  @if map-has-key($map, max) { $query: append($query, "(max-width: #{map-get($map, max)})") }
  @media screen and #{$query} { @content; }
}