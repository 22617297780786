body, html {
  font-size: 16px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $body-font;
}

.after-element {
  position: relative;
  display: inline-block;
  &:after {
    content: " ";
    position: absolute;
    bottom: -20px; left: 0;
    width: 50px;
    height: 3px;
  }
  &-blue {
    &:after {
      background: $blue;
    }
  }
  &-gold {
    &:after {
      background: $gold;
    }
  }
}