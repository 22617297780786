h1 {
  font-family: $font-serif;
  font-size: 2rem;
  line-height: 2.7rem;
}

p {
  font-family: $body-font;
  font-size: .95rem;
  line-height: 1.5rem;
  color: $black;
}

.h2 {
  font-family: $font-sans;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.7rem;
}

ul {
  li {
    font-size: 1rem;
    color: $black;
    line-height: 1.3rem;
    margin-bottom: 7px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}